import { NgModule } from '@angular/core';

import { PrivaDialogSimpleModule } from '@priva/components/dialog-simple';
import { PrivaLocalizationModule } from '@priva/localization';

import { AboutComponent } from './about.component';

@NgModule({
    declarations: [AboutComponent],
    exports: [AboutComponent],
    imports: [PrivaDialogSimpleModule, PrivaLocalizationModule],
})
export class AboutModule {}
