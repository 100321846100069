import { NgModule } from '@angular/core';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { NavigationActionTiming, provideRouterStore, routerReducer } from '@ngrx/router-store';
import { ActionReducer, MetaReducer, provideStore, StoreModule } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { ErrorHandlingService } from 'app/common/error-handling';
import { ENV_URIS_TOKEN, environmentUrisFactory } from 'app/configuration/env-provider/env-provider';
import { ProductStateModule } from 'app/product/state/product-state.module';
import { undoRedoMetaReducer } from 'app/shared/undo';
import { DatasheetStateModule } from 'app/template/datasheet/state/datasheet-state.module';

import { environment } from '../../environments/environment';
import { AppEffects } from './app.effects';
import { appReducer } from './app.reducer';

export const devToolsOptions = {
    name: environment.configuration.name,
    maxAge: 500,
    logOnly: environment.production, // Restrict extension to log-only mode})
};

export function debug(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
    return (state, action) => {
        return reducer(state, action);
    };
}

const metaReducers: MetaReducer[] =
    !environment.production && environment.debugState ? [debug, undoRedoMetaReducer] : [undoRedoMetaReducer];

/**
 * Preparation for State Management
 */
export const STORE_CONFIG = {
    runtimeChecks: {
        strictStateImmutability: false, // WvK: does not seem to work with Ivy
        strictActionImmutability: false, // WvK: does not seem to work with Ivy
        strictStateSerializability: true, // changed with Commissioning merge
        strictActionSerializability: false,
    },
};

@NgModule({
    imports: [
        DatasheetStateModule, // Get all datasheets after App initialization
        ProductStateModule, // Download latest firmware if needed after App initialization
        StoreModule.forRoot(),
        EffectsModule.forRoot(),
    ],
    providers: [
        ErrorHandlingService,
        { provide: ENV_URIS_TOKEN, useFactory: environmentUrisFactory },
        provideStore({ app: appReducer, router: routerReducer }, { metaReducers, ...STORE_CONFIG }),
        provideRouterStore({ navigationActionTiming: NavigationActionTiming.PostActivation }),
        provideEffects(AppEffects),
        provideStoreDevtools(environment.production ? {} : devToolsOptions),
    ],
})
export class AppStateModule {}
