import { NgModule } from '@angular/core';

import { PrivaSteppedDialogSimpleModule } from '@priva/components/stepped-dialog-simple';
import { PrivaLocalizationModule } from '@priva/localization';

import { IntroComponent } from './intro.component';

@NgModule({
    declarations: [IntroComponent],
    exports: [IntroComponent],
    imports: [PrivaLocalizationModule, PrivaSteppedDialogSimpleModule],
})
export class IntroModule {}
