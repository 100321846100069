import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Firmware, FirmwareResources } from '@priva/next-model';

@Injectable({
    providedIn: 'root',
})
export class ProductFilesService {
    private readonly httpClient = inject(HttpClient);

    public downloadFirmware(firmware: Firmware): Observable<FirmwareResources> {
        const urls = [firmware.imageUrl];

        return forkJoin(
            urls.map((url) =>
                this.httpClient
                    .get(url, { responseType: 'blob' })
                    .pipe(map((contents) => ({ contents, path: url }))),
            ),
        ).pipe(map(([image]) => ({ image })));
    }
}
