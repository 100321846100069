import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { PrivaDialogSimpleModule } from '@priva/components/dialog-simple';

import { AppScreenSizeDetectorDirective } from './app-screen-size-detector.directive';
import { DialogUndercutScreenLimitComponent } from './dialog-screen-size-exceed.component';

@NgModule({
    imports: [PrivaDialogSimpleModule, TranslateModule],
    declarations: [DialogUndercutScreenLimitComponent, AppScreenSizeDetectorDirective],
    exports: [DialogUndercutScreenLimitComponent, AppScreenSizeDetectorDirective],
})
export class AppScreenSizeDetectorModule {}
