import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, from, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Firmware } from '@priva/next-model';

import { FirmwareLocalStorageService } from 'app/common/local-storage';
import { FirmwareApiPaths } from 'app/configuration';
import { filterToHttpParams, getHttpHeadersAction } from 'app/core/http';
import { AppActions, AppState } from 'app/state';

import { FirmwareActions } from '.';
import { ProductFilesService } from '../product-files.service';

@Injectable({
    providedIn: 'root',
})
export class ProductApiEffects {
    private readonly actions$ = inject(Actions);
    private readonly store$ = inject<Store<{ app: AppState }>>(Store);
    private readonly httpClient = inject(HttpClient);
    private readonly productService = inject(ProductFilesService);
    private readonly firmwareStorageService = inject(FirmwareLocalStorageService);
    private readonly firmwareApiPaths: FirmwareApiPaths = inject(FirmwareApiPaths);

    public getFirmwares$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FirmwareActions.getLatestFirmwares),
            switchMap((action) => {
                const url = this.firmwareApiPaths.firmwares(action.product);
                const headers = getHttpHeadersAction(action.type);
                const params = filterToHttpParams(action?.filter);

                return this.httpClient.get<Firmware[]>(url, { params, headers }).pipe(
                    map((firmwares: Firmware[]) => {
                        return action?.filter?.latest && firmwares.length > 0
                            ? FirmwareActions.getLatestFirmwareSuccess({ firmware: firmwares[0] })
                            : FirmwareActions.getFirmwareSuccess({ firmwares });
                    }),
                    /* istanbul ignore next lvb, default api error handling */
                    catchError((e) => of(AppActions.apiError({ error: e }))),
                );
            }),
        ),
    );

    public getFirmware$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FirmwareActions.getFirmware),
            switchMap((action) => {
                const url = this.firmwareApiPaths.firmware(action.product, action.version);
                const headers = getHttpHeadersAction(action.type);

                return this.httpClient.get<Firmware>(url, { headers }).pipe(
                    map((firmware: Firmware) => FirmwareActions.getLatestFirmwareSuccess({ firmware })),
                    /* istanbul ignore next lvb, default api error handling */
                    catchError((e) => of(AppActions.apiError({ error: e }))),
                );
            }),
        ),
    );

    public downloadFirmwareIfNotExists$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FirmwareActions.getLatestFirmwareSuccess),
            withLatestFrom(this.store$),
            filter(([action, state]) => state.app.online && !!action.firmware),
            switchMap(([action]) =>
                from(this.firmwareStorageService.exists(action.firmware.version)).pipe(
                    map((hasFirmware) => ({ action, hasFirmware })),
                ),
            ),
            tap(({ hasFirmware }) => {
                if (hasFirmware) {
                    this.store$.dispatch(FirmwareActions.setFirmwareDownloaded());
                }
            }),
            filter(({ hasFirmware }) => !hasFirmware),
            map(({ action }) => FirmwareActions.downloadFirmware(action)),
        ),
    );

    public downloadFirmware$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FirmwareActions.downloadFirmware),
            switchMap((action) =>
                this.productService.downloadFirmware(action.firmware).pipe(
                    switchMap((firmwareResources) =>
                        from(
                            this.firmwareStorageService.addResources(
                                action.firmware.version,
                                firmwareResources,
                            ),
                        ),
                    ),
                    map(() => FirmwareActions.downloadFirmwareSuccess(action)),
                    catchError(() => EMPTY), // TODO: WvK: Only ignore error for now
                ),
            ),
        ),
    );

    public setFirmwareDownloaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FirmwareActions.downloadFirmwareSuccess),
            map(() => FirmwareActions.setFirmwareDownloaded()),
        ),
    );
}
