import { NgModule } from '@angular/core';

import { PrivaDialogSimpleModule } from '@priva/components/dialog-simple';
import { PrivaLocalizationModule } from '@priva/localization';

import { FeedbackComponent } from './feedback.component';

@NgModule({
    declarations: [FeedbackComponent],
    exports: [FeedbackComponent],
    imports: [PrivaDialogSimpleModule, PrivaLocalizationModule],
})
export class FeedbackModule {}
